import React, { useState, useEffect, useRef } from "react";
import { useSession } from "next-auth/react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Button } from "@mui/material";
import MenuIconButton from "./MenuIconButton";
import StatusButton from "./StatusButton";
import StatusList from "./StatusList";
import UserInfo from "./UserInfo";
import ReplayInfo from "./ReplayInfo";
import { InfluxDB } from "@influxdata/influxdb-client";
import ServicesStatus from "./ServicesStatus";
import { set as setStatus, updateServices } from "../../statusSlice";
import getDockerNodes from "./getDockerNodes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { connectPlcStatusEvent } from "../../utils/headerUtils";
import { useInterval } from "../../hooks/useInterval";
import styles from "./Header.module.scss";
import PlcControl from "../PlcControl/PlcControl";
import SelectedTrackTab from "./SelectedTrackTab";
// import { update } from "../../redux/slices/plcStatus";

function Header({
  selectedTrack,
  selectedTrackView,
  handleLeftClick,
  setOldMenuItem,
  menuItems,
  servicesJSON,
  status,
  toggleLightPanel,
  handleGeofence,
  geofenceOpen,
  leftSelectedItem,
  config,
  permissions,
  tracks,
}) {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
//   const [plcStatus, setPlcStatus] = useState("Inactive");
  const [plcControlOpen, setPlcControlOpen] = useState(false);
  const { data } = useSession({ required: false });
  const dispatch = useDispatch();
  const [dockerNodes, setDockerNodes] = useState({ up: [], down: [] });
  const dockerStatuses = useSelector((state) => state.status.services);
  const { rawData: rawServiceData } = useSelector((state) => state.status); // Use destructuring instead
  const isReplay = useAppSelector((state) => state.replay.isReplay);
  const plcStatus = useSelector((state) => state.plcStatus.status);

  const fetchData = () => {
    const queryAPI = new InfluxDB({
      url: config.url.INFLUXDB,
      token: config.influxdb_token,
    }).getQueryApi("vmonitor");

    let rows = [];
    const observer = {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row);
        rows.push(o);
        console.log("Row received: ", o); // Add this to check individual rows
      },
      error(error) {
        console.error(error);
      },
      complete() {
        console.log("Complete rows: ", rows); // Log full set of rows
        dispatch(updateServices(rows));
      },
    };

    queryAPI.queryRows(
      'from(bucket: "vmonitor") |> range(start: -1m) |> filter(fn: (r) => r["_measurement"] == "docker_swarm") |> group(columns: ["service_id", "_field"], mode:"by") |> last() |> sort(columns: ["service_name"]) |> yield(name: "last")',
      observer
    );
  };

  const healthcheck = () => {
    fetch(window.location.origin + "/api/healthcheck")
      .then((data) => {
        dispatch(setStatus({ webui: data.status === 200 }));
        // console.log("data", data);
      })
      .catch(() => dispatch(setStatus({ webui: false })));
  };

  useEffect(() => {
    console.log(rawServiceData); // Log the raw InfluxDB data in the Header
  }, [rawServiceData]);

  useInterval(() => fetchData(), 2500);
  useInterval(() => healthcheck(), 2000);

  useEffect(() => {
    const interval = setInterval(() => {
      getDockerNodes(config.host, config.node).then((nodes) =>
        setDockerNodes(nodes)
      );
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(setStatus({ nodes: dockerNodes }));
  }, [dockerNodes]);

  useEffect(() => {
    if (config.plcDisabled !== "true") {
      connectPlcStatusEvent(config, dispatch);
    }
  }, [config, dispatch]);

  function getPlcColor() {
    if (plcStatus === "Active" || plcStatus === "Secure") {
      return "success";
    } else if (plcStatus === "Inactive") {
      return "warning";
    } else if (plcStatus === "Timeout") {
      return "error";
    } else {
      return "warning";
    }
  }

  return (
    <div className={styles.header}>
      <MenuIconButton
        config={config}
        selectedTrack={selectedTrack}
        selectedTrackView={selectedTrackView}
        toggleLightPanel={toggleLightPanel}
        handleGeofence={handleGeofence}
        geofenceOpen={geofenceOpen}
        permissions={permissions}
      />
      <ul className={styles.oldItemsMenu}>
        {Object.keys(menuItems).map((menuKey) => {
          const trackLevel = menuItems[menuKey]["track-level"] || [];
          let trackStatus =
            trackLevel.find((o) => o.name === "TrackStatus")?.value || false;
          let allAlarmStatus =
            trackLevel.find((o) => o.name === "DisableAllTrackAlarms")?.value ||
            false;

          const color = () => {
            if (trackStatus || (trackStatus && allAlarmStatus)) {
              return { background: "red", color: "white" };
            } else if (allAlarmStatus && !trackStatus) {
              return { background: "yellow", color: "#3d3a7a" };
            } else if (selectedTrack === menuKey) {
              return { background: "white", color: "#3d3a7a" };
            }
            return { background: "#d8d8ea", color: "#3d3a7a" };
          };

          const decoration = () => {
            if (selectedTrack === menuKey) {
              return { textDecoration: "underline" };
            }
          };

          return (
            <li title={`${tracks[menuKey]} Alarm Control`} key={menuKey}>
              <Button
                variant="contained"
                sx={{
                  ...color(),
                  ...decoration(),
                  minWidth: 0,
                  paddingY: 0,
                  paddingX: 0.8,
                }}
                onClick={(e) => {
                  handleLeftClick(menuItems[menuKey], menuKey);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.button === 2 && permissions.canModifyAlarms) {
                    setOldMenuItem(menuItems[menuKey], menuKey);
                  }
                }}
              >
                {menuKey}
              </Button>
            </li>
          );
        })}
      </ul>
      {isReplay ? (
        <ReplayInfo />
      ) : (
        <div className={styles.logoContainer}>
          <div className={styles.clientLogoContainer}>
            <img
              alt="client-logo"
              src="./logos/clientLogo.png"
              className={styles.clientLogo}
            />
          </div>
          <div className={styles.rtsLogoContainer}>
            <img
              alt="rts-logo"
              src="./logos/rtsLogo.png"
              className={styles.rtsLogo}
            />
          </div>
        </div>
      )}
      {config.plcDisabled === "true" ? null : (
        <Button
          variant={"contained"}
          color={getPlcColor()}
          size={"small"}
          onClick={() => setPlcControlOpen(!plcControlOpen)}
        >
          PLC
        </Button>
      )}
      <Popover
        open={plcControlOpen}
        onClose={() => setPlcControlOpen(!plcControlOpen)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
      >
        <PlcControl
          config={config}
          setPlcControlOpen={setPlcControlOpen}
          plcStatus={plcStatus}
        />
      </Popover>
      <StatusButton
        status={status}
        dockerStatuses={dockerStatuses}
        dockerNodes={dockerNodes}
        onClick={() => {
          setPopOverOpen(!popOverOpen);
          setAnchorEl(divRef.current);
        }}
      />
      <UserInfo username={data?.user?.name} config={config} />
      <div ref={divRef}>
        <Popover
          open={popOverOpen}
          onClose={() => setPopOverOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          anchorEl={anchorEl}
        >
          <StatusList
            json={servicesJSON}
            statuses={dockerStatuses}
            dockerNodes={dockerNodes}
            config={config}
          />
        </Popover>
      </div>
      {selectedTrack !== config.sitePrefix && (
        <SelectedTrackTab track={selectedTrack} />
      )}
    </div>
  );
}

export default Header;
