// src/utils/headerUtils.js
import axios from "axios";
import { update } from "../redux/slices/plcStatus";

export async function postTrackView(config, view) {
  const post = await axios.post(`${config.url.API}/updateTrackView`, view);
}

export function connectPlcStatusEvent(config, dispatch) {
  const plcStatusEvent = new EventSource(`${config.url.PLC}/plc-status-event`);
  plcStatusEvent.onmessage = (e) => {
    let json = JSON.parse(e.data);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    const plcState = json?.plcStatus || "Inactive";
    dispatch(update({ status: plcState }));
  };
  plcStatusEvent.onerror = () => {
    plcStatusEvent.close();
    setTimeout(() => {
      connectPlcStatusEvent(config, dispatch);
    }, 1000);
  };
}
